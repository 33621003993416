import React, { useEffect, useContext } from 'react';
import { Header, Footer, SubHeader, BodyWrapper, Textbox, Button, LargeText, Select } from '../Components/index';
import { mergeHunks } from '../Services/Format';
import { putTemperment } from '../Services/API';
import { validateBadReview } from '../Services/Validate';
import { removeFooter, isAndriod, restoreFooter } from '../Services/DOM';
import { GridLoader } from 'react-spinners';
import { ReviewContext } from '../Contexts/review';

const BadReview = (props) => {

    const [review, setReview] = useContext(ReviewContext);

    useEffect(() => {

        setReview(review => {
          return {...review, is_loading: false, show_validation_errors: false, data: {...review.data, subject: '', negativeDropdown: ''}}
        });

        if(review.data.sentiment !== 'angry') {
            props.history.push('/home')
        }

        let payload = { sentiment: 'angry' };
        putTemperment(review.data.account_id, review.data.token, review.data.location_id, review.data.appointment_id, payload)

    }, [])

    const digestSubmit = value => {
        switch (value) {
            case 'Damage To My Item(s)':
                return 'Property Damage';
            case 'Damage(s) To My Property':
                return 'Property Damage';
            case 'I Am Missing Item(s)':
                return 'Missing Property';
            case 'Incorrect Amount Charged To My Card':
                return 'Incorrect Amount Charged';
            case '-1':
                return ''
            default:
                return 'Other'
        }
    }

    const goBack = () => props.history.goBack();

    const changeHandler = e => {
        let message = e.target.value;
        setReview((review) => {
            return {
                ...review,
                is_loading: false,
                data: {
                    ...review.data,
                    message
                }
            }
        });
        
    }

    const handleSubmit = async e => {
        e.preventDefault();

        setReview(review => {
          return {...review, is_loading: true, show_validation_errors: false}
        });

        let data = {
            subject: review.data.subject,
            message: review.data.message,
            sentiment: 'angry'
        }
        let valid = validateBadReview(data);
        if (valid.error === null) {
            // the below api call takes forever > 15s so i let it run in the background and move the user to the next page, The api is configured to handle this situation
            await putTemperment(review.data.account_id, review.data.token, review.data.location_id, review.data.appointment_id, data);
            props.history.push('/confirmation');
        }
        else {
            setReview(review => {
              return {...review, is_loading: false, show_validation_errors: true}
            });
        }
        return
    }

    const selectHandler = e => {
        let negativeDropdown = e.target.value;
        let subject = digestSubmit(negativeDropdown);
        setReview((review) => {
            return {
                ...review,
                is_loading: false,
                data: {
                    ...review.data,
                    subject,
                    negativeDropdown
                }
            }
        });
    }

    const focusHandler = () => {
        if (isAndriod() === true) {
            removeFooter();
        }
        else return
    }

    const blurHandler = () => {
        if (isAndriod() === true) {
            // delay for user exprience 
            setTimeout(() => restoreFooter(), 300)
        }
    }

    return (
        <div id='BadReview'>
            <Header back clickHandler={goBack} />
            <BodyWrapper>
                <SubHeader face unhappy message={`on ${review.data.workDate}, you had a ${review.data.workType} service with ${mergeHunks(review.data.hunks)}.`}
                    extraMessage="we're sorry you are not satisfied with your service." />
                <LargeText text="let's make things right" />
                <div className='tighter-text'>
                    <Select
                        options={['Job Is Incomplete','Damage To My Item(s)','Damage(s) To My Property','I Am Missing Item(s)','Incorrect Amount Charged To My Card','Other']}
                        changeHandler={selectHandler}
                        label='What went wrong?'
                        selected={review.data.negativeDropdown}
                    />

                    <Textbox
                        changeHandler={changeHandler}
                        ID='NegativeReview'
                        name='negative'
                        value={review.data.message}
                        placeholder='Additional Details'
                        focus={focusHandler}
                        blur={blurHandler}
                    />
                    {review.show_validation_errors === true ?
                        <div className='validation-message'>please tell us about your {review.data.workType} service.</div>

                        : null}
                    {review.is_loading === false ?
                        <Button
                            classes='float-right'
                            buttonText='submit'
                            clickHandler={handleSubmit}
                        />
                        :
                        <div className={'d-flex justify-content-center'}><GridLoader loading={true} color={'#EA7200'}/></div>
                    }
                </div>
            </BodyWrapper>
            <Footer />
        </div>
    )
}

export default BadReview;