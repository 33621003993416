import React from 'react';


const SubHeader = props => {
    if (props.face) {
        if (props.happy) {


            return (
                <div id='SubHeader' className='d-flex justify-content-between align-items-center'>
                    <img src={require('../Styles/Assets/img/happy-filled.svg')} alt='Green Happy Face' />
                    <p className='pl-3 pt-2 subheader-text'>{props.message} <span className='bold'>{props.extraMessage}</span></p>
                </div>
            )
        }
        else if (props.neutral) {
            return (
                <div id='SubHeader' className='d-flex justify-content-between align-items-center'>
                    <img src={require('../Styles/Assets/img/neutral-filled.svg')} alt='Yellow Flat Face' />
                    <p className='pl-3 pt-2 subheader-text'>{props.message}</p>
                </div>
            )
        }
        else if (props.unhappy) {
            return(
                <div id='SubHeader' className='d-flex justify-content-between align-items-center'>
                    <img src={require('../Styles/Assets/img/unhappy-filled.svg')} alt='Red Angry Face' />
                    <p className='pl-3 pt-2 subheader-text'>{props.message} <span className='bold'>{props.extraMessage}</span></p>
                </div>
            )
        }
    }
    else if (props.thumb) {
        return (
            <div id='SubHeader' className='d-flex justify-content-between align-items-center'>
                    <img src={require('../Styles/Assets/img/thumb.svg')} alt='Thumbs Up Icon' />
                <p className='pl-3 pt-3 subheader-text'>{props.message}</p>
            </div>
        )
    }
}

export default SubHeader;