import React from 'react';


const LargeText = props => {
    return (
        <div className='LargeTextContainer'>
            <p className='large-text align-self-center'>{props.text}</p>
        </div>
    )
}

export default LargeText;