
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.scss';


import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { ErrorBoundary } from './Services/BugSnag';

import { ReviewContextProvider } from './Contexts/review';


ReactDOM.render(
    <ErrorBoundary>
    	<ReviewContextProvider>
        	<App />
        </ReviewContextProvider>
    </ErrorBoundary>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
