var moment = require('moment');

export const todaysYear = date => {
    return moment(date).format('YYYY');
}
export const formatDate = date => {
    
    return moment(date).format('MM/DD/YYYY')
}


const Time = {
    todaysYear,
    formatDate,
}
export default Time;