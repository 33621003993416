import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import History from './History';
// Modules
import Home from './Modules/Home';
import BadReview from './Modules/BadReview';
import ConfirmationPage from './Modules/ConfirmationPage';
import GoodReview from './Modules/GoodReview';
import NeturalReview from './Modules/NeturalReview';
import GoodFeedback from './Modules/GoodFeedback';


const App = () => {
  const happy = require('./Styles/Assets/img/happy-filled.svg');
  const neutral = require('./Styles/Assets/img/neutral-filled.svg');
  const unhappy = require('./Styles/Assets/img/unhappy-filled.svg');
  return (
    <React.Fragment>
      <Router history={History}>
        <Switch>
          <Route exact path='/home' component={Home} />
          <Route exact path='/unfavorablereview' component={BadReview} />
          <Route exact path='/positivereview' component={GoodReview} />
          <Route exact path='/positivefeedback' component={GoodFeedback} />
          <Route exact path='/neutralreview' component={NeturalReview} />
          <Route exact path='/confirmation' component={ConfirmationPage} />
          <Redirect from='*' to='/home' />
        </Switch>
      </Router>
      <div style={{ display: 'none' }}>
        <img src={happy} alt='green happy face'/>
        <img src={neutral} alt='orange flat face'/>
        <img src={unhappy} alt='red frown face'/>
      </div>
    </React.Fragment>
  );
}
export default App;
