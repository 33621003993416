import React from 'react';

const ReviewLink = props => {
    return (
        <a href={props.externalLink} target={props.target} rel="noopener noreferrer" className='review-link-container d-flex justify-content-between align-items-center p-2' style={{ backgroundColor: props.background }} onClick={props.clickHandler}>
                <img className='review-logo-img' src={props.logourl} alt={props.logoalt} />
                <span className='logo-text'>{props.reviewSource}</span>
        </a>
    )
}


export default ReviewLink;