import rest from './rest';
import { bugsnagClient } from '../Services/BugSnag';

export const getReviewLinks = async (account_id, token, location_id) => {
    let response = await rest.get(`public/website/locations/${location_id}/settings/reviews?account_id=${account_id}&token=${token}`)
        .then(response => {
            if (response.status === 200) {
                return response.data.review_settings
            }
        }).catch(e => bugsnagClient.notify(e))

    return response
}

export const getUser = async (account_id, token, location_id) => {
    let response = await rest.get(`/public/website/locations/${location_id}/accounts/${account_id}?expand=appointments&token=${token}`)
        .then(response => {
            if (response.status === 200) {
                return response.data.accounts[0];
            }
            else return undefined
        }).catch(e => bugsnagClient.notify(e))

    return response
}

export const getHunks = async (account_id, token, location_id, appointment_id) => {
    let response = await rest.get(`/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/teams?token=${token}`)
        .then(response => {
            if (response.status === 200) {
                if (response.data.teams.length < 1) {
                    return []
                }
                else {
                    return response.data.teams[0].users
                }
            }
            else return undefined
        }).catch(e => bugsnagClient.notify(e));
    return response
}

// this is tempermant and review catch
export const putTemperment = async (account_id, token, location_id, appointment_id, payload) => {
    let response = await rest.update(`/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/reviews?token=${token}`, null, null, '', payload)
        .then(response => {
            if (response.status === 200) {
                if(response.data.reviews.length === 0) {
                    return response.data.reviews
                }
                return response.data.reviews[0]
            }
            else {
                return false
            }
        }).catch(e => bugsnagClient.notify(e))

    return response
}

export const captureClicks = async (account_id, token, location_id, appointment_id, payload) => {
    let response = await rest.post(`/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/reviews/clicks?token=${token}`, null, null, payload)
        .then(response => {
            if (response.status === 200) {
                return response.data.reviews[0]
            }
            else return false
        }).catch(e => bugsnagClient.notify(e))

    return response;
}