import React from 'react';

import { MDBRow, MDBCol, MDBAnimation } from 'mdbreact';

const ThreeFaces = props => {
    return (
        <div id='ThreeFaces'>
            <MDBRow className='justify-content-center align-items-center pt-3 question-wrapper'>
                <MDBCol size='12' className='text-center exp-text'>
                    HOW WAS YOUR EXPERIENCE?
                </MDBCol>
            </MDBRow>
            <MDBRow className='d-flex justify-content-center align-items-center face-container'>
                <MDBCol size='4' className='d-flex justify-content-center'>
                    {props.sentiment === 'angry' ?
                        <MDBAnimation type='fadeIn' duration='.75s'>
                            <img name='unhappy' src={require('../Styles/Assets/img/unhappy-filled.svg')} alt='Unhappy face' />
                        </MDBAnimation>
                        :
                        <img name='unhappy' src={require('../Styles/Assets/img/unhappy-face-empty.svg')} alt='Unhappy face' onClick={() => props.faceHandler('angry')} />
                    }
                </MDBCol>
                <MDBCol size='4' className='d-flex justify-content-center'>
                    {props.sentiment === 'neutral' ?
                        <MDBAnimation type='fadeIn' duration='.75s'>
                            <img name='neutral' src={require('../Styles/Assets/img/neutral-filled.svg')} alt='Unhappy face' />
                        </MDBAnimation>
                        :
                        <img name='neutral' src={require('../Styles/Assets/img/netural-face.svg')} alt='Neutral face' onClick={() => props.faceHandler('neutral')} />
                    }
                </MDBCol>
                <MDBCol size='4' className='d-flex justify-content-center'>
                    {props.sentiment === 'happy' ?
                        <MDBAnimation type='fadeIn' duration='.75s'>
                            <img name='happy' src={require('../Styles/Assets/img/happy-filled.svg')} alt='Unhappy face' />
                        </MDBAnimation>
                        :
                        <img name='happy' src={require('../Styles/Assets/img/happy-face-empty.svg')} alt='Happy face' onClick={() => props.faceHandler('happy')} />
                    }
                </MDBCol>
            </MDBRow>
            <MDBRow className='justify-content-center align-items-center pt-1 face-container-words'>
                <MDBCol size='4' className='d-flex justify-content-center'>
                    <span className='under-face-text'>un-satisfied</span>

                </MDBCol>
                <MDBCol size='4' className='d-flex justify-content-center'>
                    <span className='under-face-text'>neutral</span>

                </MDBCol>
                <MDBCol size='4' className='d-flex justify-content-center'>
                    <span className='under-face-text'>satisfied</span>

                </MDBCol>
            </MDBRow>
        </div>
    )
}

export default ThreeFaces;