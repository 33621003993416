import React from 'react';

const Textbox = props => {
    return(
        <div className="form-group TextBox">
            <label htmlFor={props.ID}>
            {props.label}
            </label>
            <textarea
            className="form-control text-box validate"
            id={props.ID}
            rows={props.rows}
            name={props.name}
            onChange={props.changeHandler}
            value={props.value}
            placeholder={props.placeholder}
            onFocus={props.focus}
            onBlur={props.blur}
            />
        </div>
    )
}

Textbox.defaultProps = {
    rows: '5',
    label: '',
    ID: 'FakeID',
    value: '',
    placeholder: 'I wish that my HUNKS did...',
    focus: () =>console.log('focused'),
    blur: () => console.log('blur')

}
export default Textbox;