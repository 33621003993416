import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import { todaysYear } from '../Services/Time';
const Footer = () => {
    return (
        <React.Fragment>
            <MDBRow id='ImageFooter'>
                <MDBCol size='12' className='d-flex justify-content-center align-items-center'>
                    <img className='align-self-center truck-image'src={require('../Styles/Assets/img/trip.png')} alt='Truck Leaving Building' />
                    </MDBCol>
            </MDBRow>
            <MDBRow id='Footer'>
                <MDBCol size='12' className='d-flex justify-content-center align-items-center'>
                    <span className='footer-text'>
                        Copyright &copy; 2016 - {todaysYear(Date.now())} college hunks. all rights reserved.
                    </span>
                </MDBCol>
            </MDBRow>
        </React.Fragment>
    )
}

export default Footer;