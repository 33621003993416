export const mergeHunks = hunks => {
    if(hunks === undefined || hunks === '' || (typeof hunks === 'object' && hunks.length === 0)) {
        return 'our H.U.N.K.S'
    }
    else if (hunks.length <= 2) {
        if (hunks.length === 1) { return hunks[0] }
        else {
            return hunks[0] + ' and ' + hunks[1];
        }
    }
    let bigString = ''
    hunks.forEach((d, index) => {
        if (index === hunks.length - 1) {
            return bigString = bigString + 'and ' + d
        }
        return bigString = bigString + d + ', ';
    })
    return bigString;
}

export const buildHunks = array => {
    if( array === undefined || array.length === 0) {
        return ''
    }
    let response = [];
    array.forEach(d => {
        if(typeof d === 'object') {
        response.push(d.first_name)
        }
        else {
            response.push(d)
        }
    })
    return response
}

const Format = {
    mergeHunks,
    buildHunks,
}

export default Format;