import React, { useEffect, useContext } from 'react';
import { Header, Footer, SubHeader, Textbox, Button, BodyWrapper, LargeText } from '../Components/index';
import { mergeHunks } from '../Services/Format'
import { putTemperment } from '../Services/API';
import { validateNeutralReview, userCheck } from '../Services/Validate';
import { removeFooter, isAndriod, restoreFooter } from '../Services/DOM';
import { ReviewContext } from '../Contexts/review';
import { GridLoader } from 'react-spinners';

const NeturalReview = (props) => {

    const [review, setReview] = useContext(ReviewContext);

    useEffect(() => {

        setReview(review => {
          return {...review, is_loading: false, show_validation_errors: false, data: {...review.data}}
        });

        if(review.data.sentiment !== 'neutral') {
            props.history.push('/home')
        }

        let payload = { sentiment: 'neutral' };
        putTemperment(review.data.account_id, review.data.token, review.data.location_id, review.data.appointment_id, payload)

    }, [])

    const goBack = () => props.history.goBack();

    const changeHandler = e => {
        let message = e.target.value;
        setReview((review) => {
            return {
                ...review,
                is_loading: false,
                data: {
                    ...review.data,
                    message
                }
            }
        });
        
    }

    const handleSubmit = async e => {

        e.preventDefault();

        setReview(review => {
          return {...review, is_loading: true, show_validation_errors: false}
        });

        let data = {
            message: review.data.message,
            sentiment: 'neutral'
        }
        let valid = validateNeutralReview(data);
        if (valid.error === null) {
            // the below api call takes forever > 15s so i let it run in the background and move the user to the next page, The api is configured to handle this situation
            await putTemperment(review.data.account_id, review.data.token, review.data.location_id, review.data.appointment_id, data);
            props.history.push('/confirmation');
        }
        else {
            setReview(review => {
              return {...review, is_loading: false, show_validation_errors: true}
            });
        }
        return
    }

    const focusHandler = () => {
        if (isAndriod() === true) {
            removeFooter();
        }
        else return
    }

    const blurHandler = () => {
        if (isAndriod() === true) {
            // delay for user exprience 
            setTimeout(() => restoreFooter(), 300)
        }
    }

    return (
        <div id='NeutralReview'>
            <Header back clickHandler={goBack} />
            <BodyWrapper>
                <SubHeader face neutral message={`on ${review.data.workDate}, you had a ${review.data.workType} with ${mergeHunks(review.data.hunks)}.`} />

                <LargeText text="we'd love to hear more from you" />
                <div className='tighter-text'>
                    <Textbox
                        changeHandler={changeHandler}
                        ID='NeturalReview'
                        name='neutral'
                        value={review.data.message}
                        blur={blurHandler}
                        focus={focusHandler}
                    />
                    {review.show_validation_errors === true ?
                        <div className='validation-message'>please tell us about your {review.data.workType} service.</div>
                        : null}
                    {review.is_loading === false ?
                        <Button
                            classes='float-right'
                            buttonText='submit'
                            clickHandler={handleSubmit}
                        />
                        :
                        <div className={'d-flex justify-content-center'}><GridLoader loading={true} color={'#EA7200'}/></div>
                    }
                </div>
            </BodyWrapper>
            <Footer />
        </div>
    )

}

export default NeturalReview;
