import React, { useEffect, useContext } from 'react';
import { Header, Footer, BodyWrapper, LargeText } from '../Components/index';
import { ReviewContext } from '../Contexts/review';

const ConfirmationPage = (props) => {

    const [review, setReview] = useContext(ReviewContext);

    useEffect(() => {

        if(review.data.sentiment === '') {
            props.history.push('/home')
        }

    }, [])

    let message = review.data.sentiment !== 'angry' ? 'We hope that you have a stress free day.' : 'we will be in touch with you shortly to discuss your concerns.'

    return (
        <div id='Confirmation' >
            <Header />
            <BodyWrapper>
                <LargeText text='Thank You For Your Feedback!' />
                <div className='confirmation-var-text'>{message}</div>
            </BodyWrapper>
            <Footer />
        </div >
    )

}

export default ConfirmationPage;