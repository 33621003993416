import React from 'react';

const Select = props => {
    let options = props.options.map((d, index) => {
        return (
            <option className='select-option' key={index} value={d}>{d}</option>
        )
    })
    options.unshift(<option value='-1' key={options.length + 1} className='select-option select-option-default'>Select an option</option>);

    return (
        <div className='SelectContainer'>
            <label className='select-label' htmlFor={props.ID}>{props.label}</label>
            <select className='browser-default custom-select' onChange={props.changeHandler} id={props.ID} value={props.selected}>
                {options}
            </select>
        </div>
    )
}

Select.defaultProps = {
    label: 'test',
    changeHandler: () => console.log('changed'),
    selected: ''

}
export default Select;