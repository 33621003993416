// export const baseURL = 'https://api-staging.chhj.com/rest/v1/';
// below if for full deployment
export const baseURL = process.env.NODE_ENV === 'development' ? 'https://api-staging.chhj.com/rest/v1/' : 'https://api.chhj.com/rest/v1/';
// production 'https://api.chhj.com/rest/v1/'
// testing 'https://api-testing.chhj.com/rest/v1/'
export const DEFAULT_TIMEOUT = 120000;
export const ORIGIN = 'Reviews';

export const ADD_USER_DATA = 'ADD_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
