import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer, SubHeader, ThreeFaces, BodyWrapper } from '../Components/index';
import { getReviewLinks, getUser, getHunks } from '../Services/API';
import { formatDate } from '../Services/Time'
import { buildHunks, mergeHunks } from '../Services/Format';
import { ReviewContext } from '../Contexts/review';
import {GridLoader} from 'react-spinners';
import { putTemperment } from '../Services/API';

const queryString = require('query-string');

// http://localhost:3000/home?account=58&location=136&token=8c28bce7e7bd168ed021f4f39b3507bcc22fd7b832089d8532a4eb2077049025&appointment=273
// ^^^^^ sample url training

// http://localhost:3000/home?account=3027342&location=136&token=58B4B24524C9FC5FDFF8AD86BD072A7C20B19A2D3F0D287B5CBCC59E49F9F6EB&appointment=2781728
// ^^^^^^ sample staging
const Home = (props) => {

    const [review, setReview] = useContext(ReviewContext);
    const [clickable, setClickable] = useState(true);
    const [hugeName, setHugeName] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        async function checkQueryString() {

            setReview(review => {
              return {...review, data: {...review.data, sentiment: ''}}
            });

            const qs = queryString.parse(window.location.search);
            const account_id = qs.account;
            const location_id = qs.location;
            const appointment_id = qs.appointment;
            const token = qs.token;
            if(review.data.token === '' && token === undefined) {
                // kick user out
                window.location = 'https://collegehunkshaulingjunk.com/';
            } else if(token !== undefined) {

                setReview(review => {
                  return {...review, data: {...review.data, sentiment: ''}, is_loading: true, show_validation_errors: false}
                });

                let user = await getUser(account_id, token, location_id);
                let appointment = user.appointments.filter((appt) => appt.id == appointment_id);
                let hunks = await getHunks(account_id, token, location_id, appointment_id);
                let reviewLinks = await getReviewLinks(account_id, token, location_id);
                hunks = buildHunks(hunks);
                if(user === undefined && appointment.length === 1) {
                    // kick user out
                } else {
                    setReview((review) => {
                        return {
                            ...review,
                            is_loading: false,
                            data: {
                                ...review.data,
                                account_id,
                                location_id,
                                appointment_id,
                                token,
                                name: user.first_name,
                                hunks,
                                workDate: formatDate(appointment[0].start_date),
                                workType: user.category.name,
                                feelings: '',
                                reviewLinks
                            }
                        }
                    });

                    if (user.first_name.length > 12) {
                        setHugeName(true);
                    }
                }

            }

        }

        checkQueryString();

        window.addEventListener('resize', updateSize)

        return () => {
            window.removeEventListener('resize', updateSize)
        };
        

    }, []);

    const updateSize = () => {
        setWindowWidth(window.innerWidth);
    }
    
    const handleSentiment = async (sentiment) => {
        let data = {
            sentiment
        }
        await putTemperment(review.data.account_id, review.data.token, review.data.location_id, review.data.appointment_id, data);
        
        return
    }

    const faceHandler = (sentiment) => {
        if(clickable === true) {

            setReview((review) => {
                return {
                    ...review,
                    data: {
                        ...review.data,
                        sentiment
                    }
                }
            });
            
            handleSentiment(sentiment)

            let nextUrl = '';
            if (sentiment === 'angry') {
                nextUrl = '/unfavorablereview';
            }
            else if (sentiment === 'happy') {
                nextUrl = '/positivereview';
                if(review.data.reviewLinks[0] !== undefined && review.data.reviewLinks[0].allow_text !== undefined && review.data.reviewLinks[0].allow_text === true) {
                    nextUrl = '/positivefeedback';
                }
            }
            else {
                nextUrl = '/neutralreview'
            }

            setTimeout(() => props.history.push(nextUrl), 800);

        }
        setClickable(false);
    }

    const dealWithHeader = () => {
        if (windowWidth > 768) {
            return false
        }
        else if (hugeName === false) {
            return false
        }
        else {
            return true
        }

    }

    return (
        <div id='Home'>
            {dealWithHeader() === false ? <Header dual clientName={review.data.name} /> : <Header hugeName clientName={review.data.name} />}
            <BodyWrapper>
            {review.is_loading === true
                ?
                <div className={'d-flex justify-content-center'}><GridLoader loading={true} color={'#EA7200'}/></div>
                :
                <React.Fragment>
                    <SubHeader thumb message={`on ${review.data.workDate}, you had a ${review.data.workType} service with ${mergeHunks(review.data.hunks) || ''}.`} />
                    <ThreeFaces
                        faceHandler={faceHandler}
                        sentiment={review.data.sentiment}
                    />
                </React.Fragment>
            }
            </BodyWrapper>
            <Footer />
        </div>
    )
}

export default Home;