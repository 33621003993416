const Joi = require('@hapi/joi');

const badReviewSchema = Joi.object({
    message: Joi.string().strict().min(3),
    subject: Joi.string().strict().min(3),
    sentiment: Joi.string()
});

export const validateBadReview = data => {
    return badReviewSchema.validate(data);
};

const neutralReviewSchema = Joi.object({
    message: Joi.string().strict().min(3),
    sentiment: Joi.string()
});

export const validateNeutralReview = data => {
    return neutralReviewSchema.validate(data);
};

const happyReviewSchema = Joi.object({
    message: Joi.string().strict().min(3),
    sentiment: Joi.string()
});

export const validateHappyReview = data => {
    return happyReviewSchema.validate(data);
};

export const userCheck = data => {
    if (Object.keys(data).length === 0) {
        return false
    }
    else return true
}

