import axios from 'axios';
import { baseURL, DEFAULT_TIMEOUT } from '../Constants';


const client = axios.create({
	baseURL: baseURL,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	},
	timeout: DEFAULT_TIMEOUT,
},
{withCredentials: true});

const request = function(options) {
  const onSuccess = function(response) {
		if(response === null || response === undefined) {return []};
    // console.debug('Request Successful!', response);
    return response;
  }

  const onError = function(error) {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:',  error.response.status);
      console.error('Data:',    error.response.data);
      console.error('Headers:', error.response.headers);

    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  }

  return client(options)
            .then(onSuccess)
            .catch(onError);
}

export default request;