import React, { useState, createContext, useEffect } from "react";

// Create Context Object
export const ReviewContext = createContext();

export const initialReviewState = {
	data: {
		name: '',
        hunks: '',
        workDate: '',
        workType: '',
		sentiment: '',
		message: '',
		subject: '',
		negativeDropdown: '',
		account_id: '',
		location_id: '',
		appointment_id: '',
		token: '',
		reviewLinks: []
	},
	show_validation_errors: false,
	is_loading: false	
};

// Create a provider for components to consume and subscribe to changes
export const ReviewContextProvider = props => {
	const currentState = JSON.parse(localStorage.getItem("review"));
	const [review, setReview] = useState(currentState || initialReviewState);

	useEffect(() => {
		localStorage.setItem("review", JSON.stringify(review));
	})

	return (
		<ReviewContext.Provider value={[review, setReview]}>
			{props.children}
		</ReviewContext.Provider>
	);
};