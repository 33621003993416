import React from 'react';
import PropTypes from 'prop-types';
import { MDBRow, MDBCol } from 'mdbreact';

const Header = props => {
    if(props.hugeName) {
        return (
            <React.Fragment>
                <span id='header-space'></span>
                <MDBRow id='Header' className='pl-3 pt-3 d-flex justify-content-between'>
                    <MDBCol size='5'>
                        <img className="headerlogo" src={require('../Styles/Assets/img/DualBrand-logo.png')} alt='College Hunks Hauling Junk And Moving LLC. Logo' />
                    </MDBCol>
                    <MDBCol size='7' lg='5' xl='4' className='client-container'>
                        <div className='client-text-small text-right' ><nobr>{props.clientName}</nobr></div>
                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        )
    }
    else if (props.dual) {
        return (
            <React.Fragment>
                <span id='header-space'></span>
                <MDBRow id='Header' className='pl-3 pt-3 d-flex justify-content-between'>
                    <MDBCol size='4'>
                        <img className="headerlogo" src={require('../Styles/Assets/img/DualBrand-logo.png')} alt='College Hunks Hauling Junk And Moving LLC. Logo' />
                    </MDBCol>
                    <MDBCol size='7' lg='5' xl='4' className='client-container'>
                        <div className='client-text text-right' ><nobr>Thank You, {props.clientName}</nobr></div>
                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        )
    }
    else if (props.back) {
        return (
            <React.Fragment>
                <span id='header-space'></span>
                <MDBRow id='Header' className='pt-3'>
                    <MDBCol size='1' className='align-self-center' onClick={props.clickHandler}>
                        <img src={require('../Styles/Assets/img/back-button.svg')} alt='back carret' />
                    </MDBCol>
                    <MDBCol size='4'>
                        <img className="headerlogo" src={require('../Styles/Assets/img/DualBrand-logo.png')} alt='College Hunks Hauling Junk And Moving LLC. Logo' />
                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <span id='header-space'></span>
            <MDBRow id='Header' className='pl-3 pt-3'>
                <MDBCol size='4'>
                    <img className="headerlogo" src={require('../Styles/Assets/img/DualBrand-logo.png')} alt='College Hunks Hauling Junk And Moving LLC. Logo' />
                </MDBCol>
            </MDBRow>
        </React.Fragment>
    )
}

Header.defaultProps = {
    clientName: 'User'
}

Header.propTypes = {
    clientName: PropTypes.string.isRequired,
}
export default Header;