import React, { useEffect, useContext } from 'react';
import { Header, Footer, SubHeader, BodyWrapper, LargeText, ReviewLink, Button } from '../Components/index';
import { mergeHunks } from '../Services/Format'
import { captureClicks } from '../Services/API';
import { ReviewContext } from '../Contexts/review';
import { GridLoader } from 'react-spinners';

const mapper = (data, clickEvent) => {
    if (data.length === 0) {
        return <div className={'d-flex justify-content-center'}><GridLoader loading={true} color={'#EA7200'}/></div>
    }
    let response = data.map(d => {
        return (
            <ReviewLink
                key={d.id}
                background={d.platform.color}
                logourl={d.platform.logo.url}
                logoalt={d.platform.name + ' Logo'}
                reviewSource={d.platform.name}
                externalLink={d.url}
                clickHandler={() => clickEvent(d.platform.id)}
                target={"_blank"}
            />
        )
    })
    return response
}

const GoodReview = (props) => {

    const [review, setReview] = useContext(ReviewContext);

    const goBack = () => props.history.goBack();
    let redirect;
    if(review.data.message === '') {
        redirect = () => props.history.push('/positivefeedback')
    } else {
        redirect = () => props.history.push('/confirmation')
    }
    
    const reportReview = async id => {
        let payload = {
            platform: {
                id: id
            }
        }
        // i dont need anything from this call
        await captureClicks(review.data.account_id, review.data.token, review.data.location_id, review.data.appointment_id, payload)
        redirect();
    }

    useEffect(() => {

        setReview(review => {
          return {...review, is_loading: false, show_validation_errors: false, data: {...review.data}}
        });

        if(review.data.sentiment !== 'happy') {
            props.history.push('/home')
        }

        if(review.data.reviewLinks.length === 0) {
            props.history.push('/confirmation')
        }

    }, []);

    const handleCopy = () => {
        const copyText = document.getElementById("review-content");

        if(document.body.createTextRange) {
            let range = document.body.createTextRange();
            range.moveToElementText(copyText);
            range.select();
            document.execCommand("Copy");
            alert("Copied to clipboard");
        }
        else if(window.getSelection) {
            // other browsers

            let selection = window.getSelection();
            let range = document.createRange();
            range.selectNodeContents(copyText);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("Copy");
            alert("Copied to clipboard");
        }

    }

    return (
        <div id='GoodReview'>
            <Header back clickHandler={goBack} />

            <BodyWrapper>
                <SubHeader face happy message={`on ${review.data.workDate}, you had a ${review.data.workType} service with ${mergeHunks(review.data.hunks)}.`} extraMessage="we're glad you are satisfied with your service." />
                {/*<LargeText text='your feedback' />*/}
                {/*<p className='reminder-text' id="review-content">{review.data.message}</p>*/}
                {/*{review.data.message !== ''*/}
                {/*    ?*/}
                {/*    <p style={{textAlign: 'center' }}>*/}
                {/*        <Button*/}
                {/*            buttonText='Copy to Clipboard'*/}
                {/*            clickHandler={handleCopy}*/}
                {/*        />*/}
                {/*    </p>*/}
                {/*    :*/}
                {/*    null*/}
                {/*}*/}
                {review.data.message === '' ? 
                    <p className='reminder-text' style={{marginTop: '40px'}}>We'd love it if you shared your feedback on these platforms</p>
                    :
                    <p className='reminder-text' style={{marginTop: '40px'}}>Thank you for your feedback! We'd love it if you shared on these platforms as well</p>
                }
                <div>
                    {mapper(review.data.reviewLinks, reportReview)}
                    {review.data.message === '' && 
                        <ReviewLink
                            key={"comment-link"}
                            background={"#EA7200"}
                            logourl={"https://hunkware.s3.amazonaws.com/chhj-reviews-images/leave_comment.png"}
                            logoalt={'Comments'}
                            reviewSource={"... or share with us"}
                            externalLink={"/positivefeedback"}
                            clickHandler={() => null}
                            target={"_self"}
                        />
                    }
                </div>
            </BodyWrapper>
            <Footer />

        </div>
    )
}

export default GoodReview;