import React from 'react';

const BodyWrapper = props => {
    return (
        <div className='BodyComponent p-4'>
            {props.children}
        </div>
    )
}

export default BodyWrapper;