export const removeFooter = () => {
    let i = document.getElementById('ImageFooter');
    let f = document.getElementById('Footer');

    i.className += ' hide';
    f.className += ' hide';

}

export const restoreFooter = () => {
    let i = document.getElementById('ImageFooter');
    let f = document.getElementById('Footer');
    i.classList.remove('hide');
    f.classList.remove('hide');
}
export const isAndriod = () => {
    let stuff = navigator.userAgent.split(' ');
    if (stuff.includes('Android')) {
        return true
    }
    else {
        return false
    }
}