import React from 'react';

import {MDBBtn} from 'mdbreact';

const Button = props => {
    return(
        <MDBBtn disabled={props.disabled} color='primary' onClick={props.clickHandler} name={props.name} className={props.classes}>{props.buttonText}</MDBBtn>
    )
}
Button.defaultProps = {
    disabled: false
}

export default Button;